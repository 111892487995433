import axios from 'axios';
const apiURL = process.env.NODE_ENV === 'production' ? 'backend/' : process.env.REACT_APP_API_URL;
const axiosInstance = axios.create({
  baseURL: apiURL + 'api/', //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});
const isTokenExpired = token => {
  if (!token) {
    // Token not provided
    return true;
  }

  const decodedToken = parseJwt(token);

  if (!decodedToken || !decodedToken.exp) {
    // Invalid token format or expiration claim not found
    return true;
  }

  // Compare the expiration timestamp with the current time
  const currentTime = Math.floor(Date.now() / 1000);
  return decodedToken.exp < currentTime;
};

const parseJwt = token => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    // Invalid token format
    return null;
  }
};
const token = localStorage.getItem('token');
if (!isTokenExpired(token)) {
  axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${token}`;
}

export default axiosInstance;
