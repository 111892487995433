import React, { useContext } from 'react';
// import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import AppContext from '@jumbo/components/contextProvider/AppContextProvider/AppContext';
import { localStorage_getItem } from 'common/LocalStorage';

const Logo = ({ color, ...props }) => {
  const contextValue = useContext(AppContext);
  // let logoUrl = '';
  // let logoSymbolUrl = '';
  let company_logo = localStorage_getItem('company_logo');

  // if(contextValue.logo_img)
  // {
  //   logoUrl = color === 'white' ? contextValue.logo_img : contextValue.logo_img;
  //   logoSymbolUrl = color === 'white' ? contextValue.logo_img : contextValue.logo_img;
  // }
  // else
  // {
  //   logoUrl = color === 'white' ? '/images/npc.png' : '/images/npc.png';
  //   logoSymbolUrl = color === 'white' ? '/images/npc.png' : '/images/npc.png';
  // }

  return (
    <Box className="pointer" {...props}>
      <NavLink to="/">
        <CmtImage src={'/images/logo.jpg'} alt="logo" height={props.height || 50} />
      </NavLink>
    </Box>
  );
};

export default Logo;
