import { useQuery } from 'react-query';
import Notification from 'services/api/notification';
import { useSelector } from 'react-redux';

export default function useFrequency() {
  const { authUser } = useSelector(({ auth }) => auth);
  return useQuery(['frequency'], () => Notification.getFrequency(), {
    enabled: !!authUser,
    refetchInterval: 1000 * 60 * 5,
  });
}
