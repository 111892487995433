import ls from 'localstorage-slim';
import encUTF8 from 'crypto-js/enc-utf8';
import AES from 'crypto-js/aes';

ls.config.encrypt = true;
let secret_file = {
  user_type: 'secret_1',
  user_id: 'secret_2',
  analysisLayout: 'secret_3',
  company_id: 'secret_4',
  email: 'secret_5',
  monitorLayout: 'secret_6',
  user_name: 'secret_7',
  access_list: 'secret_8',
  token: 'secret_9',
  company_slug: 'secret_10',
  portfolio_type: 'secret_11',
  stateidToName: 'secret_12',
  siteidToStateid: 'secret_13',
  site_detail: 'secret_14',
  first_name: 'secret_15',
  last_name: 'secret_16',
  profile_image: 'secret_17',
  verify_user: 'secret_18',
  company_name: 'secret_19',
  session_id: 'secret_20',
  company_logo: 'secret_21',
  theme: 'secret_22',
};

// update encrypter to use AES encryption
ls.config.encrypter = (data, secret) => AES.encrypt(JSON.stringify(data), secret).toString();

// update decrypter to decrypt AES-encrypted data
ls.config.decrypter = (data, secret) => {
  try {
    return JSON.parse(AES.decrypt(data, secret).toString(encUTF8));
  } catch (e) {
    // incorrect/missing secret, return the encrypted data instead
    return data;
  }
};

export const localStorage_setItem = (key, value, encrypt = true) => {
  let encrpy_key = key; // AES.encrypt(JSON.stringify(key), 'secret_key').toString();
  if (encrypt === true) {
    ls.set(encrpy_key, value, { encrypt: true, secret: secret_file[key] });
  } else {
    ls.set(encrpy_key, value);
  }
};
export const localStorage_getItem = (key, decrypt = true) => {
  let encrpy_key = key; //AES.encrypt(JSON.stringify(key), 'secret_key').toString();
  if (decrypt === true) {
    return ls.get(encrpy_key, { decrypt: true, secret: secret_file[key] });
  } else {
    return ls.get(encrpy_key);
  }
};
export const localStorage_removeItem = key => {
  ls.remove(key);
};
export const localStorage_clear = () => {
  ls.clear();
};
