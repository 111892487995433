import React from 'react';
import { PostAdd } from '@material-ui/icons';

export const sidebarNavs = [
  {
    name: 'Main',
    type: 'section',
    children: [
      {
        name: 'Home',
        type: 'item',
        icon: <PostAdd />,
        link: '/home',
      },
      {
        name: 'Notifications & Alerts',
        type: 'item',
        icon: <PostAdd />,
        link: '/notification-alert',
      },
      {
        name: 'Plant Portfolio',
        type: 'item',
        icon: <PostAdd />,
        link: '/plant-portfolio',
      },
      {
        name: 'Forecast Analysis',
        type: 'item',
        icon: <PostAdd />,
        link: '/forecast-analysis',
      },
      {
        name: 'Auto Scheduling',
        type: 'item',
        icon: <PostAdd />,
        link: '/auto-scheduling',
      },
      {
        name: 'Reports',
        type: 'item',
        icon: <PostAdd />,
        link: '/reports',
      },
      {
        name: 'Upload Data',
        type: 'item',
        icon: <PostAdd />,
        link: '/upload-data',
      },
      {
        name: 'Logs',
        type: 'item',
        icon: <PostAdd />,
        link: '/logs',
      },
      // {
      //   name: 'Dayahead Forecast',
      //   type: 'item',
      //   icon: <PostAdd />,
      //   link: '/dayahead-forecast',
      // },
      // {
      //   name: 'Availability',
      //   type: 'item',
      //   icon: <PostAdd />,
      //   link: '/availability',
      // },
      // {
      //   name: 'Weather',
      //   type: 'item',
      //   icon: <PostAdd />,
      //   link: '/weather',
      // },
      // {
      //   name: 'Upload Data',
      //   type: 'item',
      //   icon: <PostAdd />,
      //   link: '/upload-data',
      // },
    ],
  },
  {
    name: 'Management',
    type: 'section',
    children: [
      {
        name: 'Company Management',
        type: 'item',
        icon: <PostAdd />,
        link: '/company-management',
      },
      {
        name: 'Group Management',
        type: 'item',
        icon: <PostAdd />,
        link: '/group-management',
      },
      {
        name: 'User Management',
        type: 'item',
        icon: <PostAdd />,
        link: '/user-management',
      },
      {
        name: 'Module Management',
        type: 'item',
        icon: <PostAdd />,
        link: '/module-management',
      },
    ],
  },
];

export const horizontalDefaultNavs = [
  {
    name: 'Main',
    type: 'collapse',
    children: [
      {
        name: 'Sample',
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: 'Main',
    type: 'collapse',
    children: [
      {
        name: 'Sample',
        type: 'item',
        icon: <PostAdd />,
        link: '/sample-page',
      },
    ],
  },
];
