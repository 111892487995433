import React, { useState, useEffect } from 'react';
import moment from 'moment';

const Timer = () => {
  const [currentDate, setCurrentDate] = useState('');
  useEffect(() => {
    let timer = setInterval(() => {
      setCurrentDate(moment().format('DD-MM-YYYY HH:mm:ss'));
    });
    return () => {
      clearInterval(timer);
    };
  });
  return <>Date: {currentDate}</>;
};

export default Timer;
