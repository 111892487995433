import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import axios from '../config';

const GroupManagement = {
  overalllGroupInfo: (setdata, year, company_id, user_type) => {
    return dispatch => {
      axios
        .get('group/count?fyear=' + year + '&company_id=' + company_id + '&user_type=' + user_type)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            setdata(data['data']);
          } else {
            setdata({
              total: '-',
              active: '-',
              inactive: '-',
              blocked: '-',
            });
          }
        })
        .catch(function(error) {
          setdata({
            total: '-',
            active: '-',
            inactive: '-',
            blocked: '-',
          });
        });
    };
  },

  changeStatus: (group_id, status) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('group/change-status/' + group_id, { status: status.toLowerCase() })
        .then(({ data }) => {
          if (data['status'] === 'success') {
            dispatch(fetchSuccess(data['message']));
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message));
        });
    };
  },

  deleteGroup: groupID => {
    return new Promise((resolve, reject) => {
      axios
        .delete('group/delete/' + groupID)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            resolve(data['data']);
          } else {
            reject(data);
          }
        })
        .catch(function(error) {
          reject(error.response.data);
        });
    });
  },

  groupList: (setdata, year, company_id, user_type) => {
    return dispatch => {
      axios
        .get('group/get?fyear=' + year + '&company_id=' + company_id + '&user_type=' + user_type + '&type=list')
        .then(({ data }) => {
          if (data['status'] === 'success') {
            setdata(data['data']);
          } else {
            // dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          // dispatch(fetchError(error.response.data.message));
        });
    };
  },

  groupWithStatus: (setdata, year, company_id, status, user_type) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .get('group/get?fyear=' + year + '&company_id=' + company_id + '&user_type=' + user_type + '&status=' + status)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            dispatch(fetchSuccess(data['message']));
            setdata(data['data']);
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message));
        });
    };
  },

  addNewGroup: (groupDetails, setgroup_id, setIsUpdated) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('group/create', groupDetails)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            dispatch(fetchSuccess(data.message));
            setgroup_id(data.data['id']);
            // console.log(data);
            setIsUpdated(1);
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          // setUpdateGroup(error.response);
          if (error.response.data.status === 'error') {
            dispatch(fetchError(error.response.data.message));
          } else {
            dispatch(fetchError(error.response.data.message));
          }
        });
    };
  },

  getCurrentGroup: (groupId, setUpdateGroup, setIsUpdated) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .get('group/get/' + groupId)
        .then(({ data }) => {
          if (data.status === 'success') {
            dispatch(fetchSuccess(data.message));
            setUpdateGroup(data);
            setIsUpdated(0);
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message));
        });
    };
  },

  updateGroup: (groupId, groupData, setIsUpdated) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .put('group/set/' + groupId, groupData)
        .then(({ data }) => {
          if (data.status === 'success') {
            dispatch(fetchSuccess(data.message));
            setIsUpdated(1);
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          if (error.response.data.status === 'error') {
            dispatch(fetchError(error.response.data.message));
          } else {
            dispatch(fetchError(error.response.data.message));
          }
        });
    };
  },
};

export default GroupManagement;
