import React from 'react';
import { List } from '@material-ui/core';
import NavMenuItem from './NavMenuItem';
import NavSection from './NavSection';
import NavCollapse from './NavCollapse';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { isNull, isUndefined } from 'lodash';
import { localStorage_getItem } from '../../../common/LocalStorage';

const useStyles = makeStyles(theme => ({
  sideNavMenu: {
    position: 'relative',
  },
}));

const generateList = (menuItems, user_type, all_access_list) => {
  if (user_type == '1') {
    return menuItems.map((item, index) => {
      if (item.children.length > 0) {
        switch (item.type) {
          case 'section':
            return <NavSection {...item} key={index} />;
          case 'collapse':
            return <NavCollapse {...item} key={index} />;
          case 'item':
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      }
    });
  } else {
    const filteredData1 = menuItems.map(section => ({
      ...section,
      children: section.children.filter(child => all_access_list.includes(child.name)),
    }));
    return filteredData1.map((item, index) => {
      if (item.children.length > 0) {
        switch (item.type) {
          case 'section':
            return <NavSection {...item} key={index} />;
          case 'collapse':
            return <NavCollapse {...item} key={index} />;
          case 'item':
            return <NavMenuItem {...item} key={index} />;
          default:
            return null;
        }
      }
    });
  }
};

const CmtVertical = props => {
  const { menuItems } = props;
  const classes = useStyles();
  const user_type = localStorage_getItem('user_type');
  let access_list = localStorage_getItem('access_list');
  let menuList = null;
  const mergedChildren = menuItems.reduce((acc, section) => {
    return acc.concat(section.children || []);
  }, []);
  if (!isNull(access_list)) {
    if (isUndefined(access_list['edit_page_level_access'])) {
      access_list['edit_page_level_access'] = [];
    }
    if (isUndefined(access_list['view_page_level_access'])) {
      access_list['view_page_level_access'] = [];
    }
    let all_access_list = [...access_list['view_page_level_access'], ...access_list['edit_page_level_access']];
    menuList = generateList(menuItems, user_type, all_access_list);
  }
  return (
    <List component="nav" disablePadding className={classes.sideNavMenu}>
      {menuList}
    </List>
  );
};

export default CmtVertical;
