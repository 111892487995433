import React, { useContext } from 'react';
import { Typography } from '@material-ui/core';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import { useDispatch } from 'react-redux';
// import { AuhMethods } from '../../../../services/auth';
// import { CurrentAuthMethod } from '../../../constants/AppConstants';
import SidebarThemeContext from '../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import { localStorage_getItem } from 'common/LocalStorage';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '30px 16px 12px 16px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}));

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const user_name = localStorage_getItem('user_name');
  const profile_image = localStorage_getItem('profile_image');
  const apiURL = process.env.NODE_ENV === 'production' ? '' : process.env.REACT_APP_API_URL;

  //const dispatch = useDispatch();

  // const [anchorEl, setAnchorEl] = React.useState(null);

  // const handlePopoverOpen = event => {
  //   setAnchorEl(event.currentTarget);
  // };

  // const open = Boolean(anchorEl);

  return (
    <div className={classes.root}>
      {profile_image && <CmtAvatar src={apiURL + profile_image} alt="Npcl" />}
      {!profile_image && <CmtAvatar src={'/images/user.svg'} alt="NPCL" />}
      <div className={classes.userInfo}>
        <div
          className="pointer"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}>
          <div className="mr-2">
            <Typography className={classes.userSubTitle}>{user_name}</Typography>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarHeader;
