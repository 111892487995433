import React, { useContext, useMemo } from 'react';

// import { IntlProvider } from 'react-intl';
import MomentUtils from '@date-io/moment';
import { create } from 'jss';
import rtl from 'jss-rtl';

import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { createTheme, jssPreset, StylesProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { useSelector } from 'react-redux';
import defaultContext from '../contextProvider/AppContextProvider/defaultContext';
import AppContext from '../contextProvider/AppContextProvider/AppContext';
// import AppLocale from '../../../i18n';
import AppLayout from '../AppLayout';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const initialThemeValue = defaultContext.theme;

const AppWrapper = ({ children }) => {
  const { theme } = useContext(AppContext);

  const muiTheme = useMemo(() => {
    return createTheme(theme);
  }, [theme]);

  const resetTheme = useMemo(() => {
    return createTheme(initialThemeValue);
  }, []);
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    // <IntlProvider locale={AppLocale[locale.locale].locale} messages={AppLocale[locale.locale].messages}>
    <ThemeProvider theme={authUser ? muiTheme : resetTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <StylesProvider jss={jss}>
          <CssBaseline />
          <AppLayout>{children}</AppLayout>
        </StylesProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
    // </IntlProvider>
  );
};

export default AppWrapper;
