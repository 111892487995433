import React from 'react';
import SidebarToggleHandler from '../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, Tooltip, IconButton, Hidden, Typography } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from 'services/auth';
import { CurrentAuthMethod } from '@jumbo/constants/AppConstants';
// import LanguageSwitcher from '../LanguageSwitcher';
import makeStyles from '@material-ui/core/styles/makeStyles';
// import SearchIcon from '@material-ui/icons/Search';
// import AppsMenu from './AppsMenu';
import HeaderNotifications from './HeaderNotifications';
// import HeaderMessages from './HeaderMessages';
import Logo from '../Logo';
import CmtImage from '@coremat/CmtImage';
import Timer from './Timer';
// import SearchPopover from '../SearchPopover';
import { localStorage_getItem } from 'common/LocalStorage';
import useFrequency from 'queries/hooks/useFreqency';
import UserDropDown from '../UserDropDown';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 64,
    backgroundColor: theme.palette.primary,
    [theme.breakpoints.up('md')]: {
      minHeight: 72,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
  },
  logo: {
    paddingRight: 8,
  },
  searchRoot: {
    position: 'relative',
    width: 260,
    [theme.breakpoints.up('md')]: {
      width: 350,
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      left: 18,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      height: 48,
      borderRadius: 30,
      backgroundColor: alpha(theme.palette.common.dark, 0.38),
      color: alpha(theme.palette.common.white, 0.7),
      boxSizing: 'border-box',
      padding: '5px 15px 5px 50px',
      transition: 'all 0.3s ease',
      '&:focus': {
        backgroundColor: alpha(theme.palette.common.dark, 0.58),
        color: alpha(theme.palette.common.white, 0.7),
      },
    },
  },
  langRoot: {
    borderLeft: `solid 1px ${alpha(theme.palette.common.dark, 0.15)}`,
    minHeight: 72,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 8,
    paddingRight: 8,
    position: 'relative',
    [theme.breakpoints.down('sm')]: {
      minHeight: 64,
    },
  },
  iconBtn: {
    color: alpha(theme.palette.secondary.light, 0.8),
    '&:hover, &:focus': {
      color: theme.palette.secondary.light,
    },
  },
}));

const Header = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const { data } = useFrequency();
  const classes = useStyles();
  const dispatch = useDispatch();
  const onLogoutClick = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };
  const company_name = localStorage_getItem('company_name');
  return (
    <Toolbar className={classes.root}>
      {authUser && <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />}
      <Logo ml={2} color="white" className={classes.logo} />
      {/* <Hidden smDown>Noida Power Company Limited</Hidden> */}
      <Hidden smDown>{company_name}</Hidden>
      <Hidden mdUp>{company_name}</Hidden>
      <br />
      <Timer />
      <Box flex={1} />
      {/* <Hidden smDown>
        <Box pr={3} className={classes.searchRoot}>
          <InputBase placeholder={'Search here...'} inputProps={{ 'aria-label': 'search' }} />
          <SearchIcon />
        </Box>
      </Hidden>
      <Hidden mdUp>
        <SearchPopover iconClassName={classes.iconBtn} />
      </Hidden> */}
      {/* <AppsMenu /> */}
      {/* <HeaderMessages /> */}
      {/* <HeaderNotifications /> */}
      <Typography>NR Freq: {data} </Typography>
      <Box className={classes.langRoot}>
        <CmtImage src="/images/cc-logo.svg" height={30}></CmtImage>
      </Box>
      {authUser && <UserDropDown />
      // (
      //   <IconButton onClick={onLogoutClick} className={classes.iconBtn}>
      //     <Tooltip title="Logout">
      //       <ExitToAppIcon />
      //     </Tooltip>
      //   </IconButton>
      // )
      }
    </Toolbar>
  );
};

export default Header;
