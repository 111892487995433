import axios from '../config';

const Modules = {
  getList: async () => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/module/get-module-permissions`)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            resolve(data.data);
          } else {
            reject(data);
          }
        })
        .catch(function(error) {
          // console.log(error);
          reject(error.response.data);
        });
    });
  },
  create: async postData => {
    return new Promise((resolve, reject) => {
      axios
        .post(`/module/create`, postData)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            resolve(data);
          } else if (data['errors'] && data['errors'].length > 0) {
            reject(data['errors'][0]);
          } else {
            reject(data);
          }
        })
        .catch(function(error) {
          // console.log(error);
          reject(error.response?.data);
        });
    });
  },
  update: async postData => {
    return new Promise((resolve, reject) => {
      axios
        .put(`/module/update/${postData.id}`, postData)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            resolve(data);
          } else if (data['errors'] && data['errors'].length > 0) {
            reject(data['errors'][0]);
          } else {
            reject(data);
          }
        })
        .catch(function(error) {
          reject(error.response.data);
        });
    });
  },
  view: async moduleId => {
    return new Promise((resolve, reject) => {
      axios
        .get(`/module/view/${moduleId}`)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            resolve(data['data']);
          } else {
            reject(data);
          }
        })
        .catch(function(error) {
          // console.log(error);
          reject(error.response.data);
        });
    });
  },
  delete: async moduleId => {
    return new Promise((resolve, reject) => {
      axios
        .delete(`/module/delete/${moduleId}`)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            resolve(data['data']);
          } else {
            reject(data);
          }
        })
        .catch(function(error) {
          // console.log(error);
          reject(error.response.data);
        });
    });
  },
};

export default Modules;
