import { QueryClient } from 'react-query';
import { store } from 'App';
import { fetchError } from 'redux/actions';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Default query options
      onError: err => {
        if (err?.response?.data?.message) {
          store.dispatch(fetchError(err?.response?.data?.message));
        } else if (err?.response?.data?.errors?.length > 0) {
          store.dispatch(fetchError(err?.response?.data?.errors[0]));
        } else {
          store.dispatch(fetchError(err.message || ''));
        }
      },
    },
  },
});

export default queryClient;
