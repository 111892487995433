import { localStorage_setItem, localStorage_getItem } from 'common/LocalStorage';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import axios from '../config';

const UserManagement = {
  saveInLocalStorage: async userId => {
    // axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    // console.log(token)
    return new Promise((resolve, reject) => {
      axios
        .get('user/get/' + userId)
        .then(({ data }) => {
          if (data['status'] === 'success' && data['data'].length !== 0) {
            // console.log(data)
            localStorage_setItem('user_id', userId);
            localStorage_setItem('company_name', data['data'][0]['company_details']['name']);
            localStorage_setItem('company_id', data['data'][0]['company_details']['id']);
            localStorage_setItem('email', data['data'][0]['email']);
            localStorage_setItem('user_name', data['data'][0]['user_name']);
            localStorage_setItem('user_type', data['data'][0]['user_type']);
            localStorage_setItem('company_logo', data['data'][0]['company_details']['logo']);
            localStorage_setItem('theme', data['data'][0]['company_details']['theme']);
            localStorage_setItem('profile_image', data['data'][0]['profile_image']);
            // localStorage.setItem('verify_user', data['data']['verify_user']);
            resolve('true');
          } else {
            reject('Invalid User');
          }
        })
        .catch(function(error) {
          console.log('error occured', error);
        });
    });
  },
  userList: (setdata, year, company_id, user_type) => {
    return dispatch => {
      //dispatch(fetchStart());
      axios
        .get('user/get?fyear=' + year + '&company_id=' + company_id + '&user_type=' + user_type)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            setdata(data['data']);
          } else {
            setdata([]);
          }
        })
        .catch(function(error) {
          setdata([]);
        });
    };
  },
  overalllUserInfo: (setdata, year, company_id, user_type) => {
    return dispatch => {
      //dispatch(fetchStart());
      axios
        .get('user/count?fyear=' + year + '&company_id=' + company_id + '&user_type=' + user_type)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            setdata(data['data']);
            // console.log(data);
            //dispatch(fetchSuccess(data['message']));
          } else {
            setdata({
              total: '-',
              active: '-',
              inactive: '-',
              blocked: '-',
            });
            //dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          setdata({
            total: '-',
            active: '-',
            inactive: '-',
            blocked: '-',
          });
          //dispatch(fetchError(error.response.data.message));
        });
    };
  },
  addNewUser: (user, seteditedUserId, seteditedUserComapny, setIsUpdated) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('user/set', user)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            dispatch(fetchSuccess(data.message));
            seteditedUserId(data['data']['id']);
            seteditedUserComapny(data['data']['company_name']);
            setIsUpdated(1);
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          if (error.response.data.status === 'error') {
            dispatch(fetchError(error.response.data.message));
          } else {
            dispatch(fetchError(error.message));
          }
        });
    };
  },
  getCurrentUser: (userId, setCurrentUser, setIsUpdated) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .get('user/get/' + userId)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            data['data']['group_id'] = data['group_id'];
            setCurrentUser(data);
            setIsUpdated(0);
            //dispatch(fetchSuccess(data['message']));
          } else {
            //dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message));
        });
    };
  },
  deleteUser: id => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .delete('user/delete/' + id)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            // console.log(data['message']);
            dispatch(fetchSuccess(data['message']));
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message));
        });
    };
  },

  updateUser: (userId, user, setIsUpdated) => {
    // console.log(' user ', user);
    return dispatch => {
      dispatch(fetchStart());
      axios
        .put('user/set/' + userId, user)
        .then(({ data }) => {
          if (data.status === 'success') {
            // change user_name in local storage
            let profile_pic = data.data.profile_image;
            if (userId === localStorage_getItem('user_id') && user.user_name !== localStorage_getItem('user_name')) {
              localStorage_setItem('user_name', user.user_name);
            }
            if (userId === localStorage_getItem('user_id') && profile_pic !== localStorage_getItem('profile_image')) {
              localStorage_setItem('profile_image', profile_pic);
            }
            setIsUpdated(1);
            dispatch(fetchSuccess(data.message));
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          console.log(error);
          if (error.response.data.status === 'error') {
            dispatch(fetchError(error.response.data.message));
          } else {
            dispatch(fetchError(error.message));
          }
        });
    };
  },

  changeStatus: (user_id, status) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('/user/change-status/' + user_id, { status: status.toLowerCase() })
        .then(({ data }) => {
          if (data['status'] === 'success') {
            // console.log(data);
            // setmsg(data['message']);
            dispatch(fetchSuccess(data['message']));
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.response.data.message));
        });
    };
  },
};

export default UserManagement;
