import { STORE_PLANTS, SELECT_PLANT } from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  plants: [],
  selectedPlant: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case STORE_PLANTS: {
      return {
        selectedPlant: action.payload[0],
        plants: [...action.payload],
      };
    }
    case SELECT_PLANT: {
      localStorage.setItem('selectedPlant', action.payload);
      return {
        ...state,
        selectedPlant: state.plants.find(plant => plant.plant_id == action.payload),
      };
    }
    default:
      return state;
  }
};
