import CompanyManagement from '../services/api/company';
import UserManagement from '../services/api/user';
import GroupManagement from './api/group';
import ModuleManagement from './api/modules';
import PlantManagement from './api/plant';

export const AuhMethods = {
  jwtCompanyManagement: CompanyManagement,
  jwtGroupManagement: GroupManagement,
  jwtUserManagement: UserManagement,
  jwtModuleManagement: ModuleManagement,
  jwtPlant: PlantManagement,
};
