// import { localStorage_setItem } from 'common/LocalStorage'
import { isNull } from 'lodash';
import { fetchError, fetchStart, fetchSuccess } from '../../redux/actions';
import axios from '../config';
import { localStorage_setItem } from 'common/LocalStorage';

const CompanyManagement = {
  getCurrentCompanyDetail: (companyId, setcolour, setLogo) => {
    return () => {
      axios
        .get('company/get/' + companyId)
        .then(({ data }) => {
          console.log(data);
          if (data['status'] === 'success') {
            if (!isNull(data['data'])) {
              setcolour(isNull(data['data']['theme']) ? 'Default' : data['data']['theme']);
              if (!isNull(data['data']['logo'])) {
                setLogo(data['data']['logo']);
              } else {
                setLogo('');
              }
              localStorage_setItem('site_detail', data['data']['sites']);
            }
          }
        })
        .catch(function(error) {
          // dispatch(fetchError(error.message));
        });
    };
  },
  companyList: (setCurrentCompany, year, company_id, user_type) => {
    return dispatch => {
      // dispatch(fetchStart());
      axios
        .get('company/get?fyear=' + year + '&company_id=' + company_id + '&user_type=' + user_type)
        .then(({ data }) => {
          // console.log(data);
          if (data['status'] === 'success') {
            //dispatch(fetchSuccess('done'));
            setCurrentCompany(data['data']);
            // console.log(data);
          } else {
            setCurrentCompany([]);
            // dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          setCurrentCompany([]);
          // dispatch(fetchError(error.message));
        });
    };
  },

  addNewCompany: (company, setCurrentCompany, setIsUpdated) => {
    return dispatch => {
      dispatch(fetchStart());
      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };
      axios
        .post('company/set', company, config)
        .then(({ data }) => {
          console.log(data['status']);
          // setCurrentCompany(data);
          if (data['status'] === 'success') {
            setCurrentCompany(data);
            setIsUpdated(1);
            dispatch(fetchSuccess(data['message']));
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          // setCurrentCompany(error.response);
          if (error.response.data.status === 'error') {
            dispatch(fetchError(error.response.data.message));
          } else {
            dispatch(fetchError(error.message));
          }
        });
    };
  },

  changeStatus: (company_id, status) => {
    return dispatch => {
      // dispatch(fetchStart());
      axios
        .post('/company/change-status/' + company_id, { status: status })
        .then(({ data }) => {
          // setdata(data);
          // console.log(data);
          if (data['status'] === 'success') {
            // dispatch(fetchSuccess(data['message']));
          } else {
            // dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          // dispatch(fetchError(error.message));
        });
    };
  },

  deleteCompany: id => {
    return new Promise((resolve, reject) => {
      axios
        .delete('company/delete/' + id)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            resolve(data['data']);
          } else {
            reject(data);
          }
        })
        .catch(function(error) {
          reject(error.response.data);
        });
    });
  },

  getCurrentCompany: (companyId, setCurrentCompany) => {
    return dispatch => {
      //dispatch(fetchStart());
      axios
        .get('company/get/' + companyId)
        .then(({ data }) => {
          if (data['status'] === 'success') {
            setCurrentCompany(data);
          } else {
            // dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {
          // dispatch(fetchError(error.message));
        });
    };
  },
  updateCompany: (companyId, formdata, setIsUpdated) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .put('company/set/' + companyId, formdata)
        .then(({ data }) => {
          if (data.status === 'success') {
            setIsUpdated(1);
            dispatch(fetchSuccess(data.message));
          } else {
            dispatch(fetchError(data['message']));
          }
        })
        .catch(function(error) {});
    };
  },
};

export default CompanyManagement;
