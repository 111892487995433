import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import AppContext from './AppContext';
import defaultContext from './defaultContext';
import { darkTheme, lightTheme, semiDarkTheme } from '../../../../theme/themeColors';
import { isNull, isUndefined } from 'lodash';
import { AuhMethods } from 'services';
import { localStorage_getItem } from 'common/LocalStorage';
import { useDispatch, useSelector } from 'react-redux';
import { theme_obj } from 'common/ComponentConstant';

const initialThemeValue = defaultContext.theme;
const otherThemes = {
  light: lightTheme,
  'semi-dark': semiDarkTheme,
  dark: darkTheme,
};

const AppContextProvider = ({ children }) => {
  const [locale, setLocale] = useState(defaultContext.defaultLng);
  const [theme, setTheme] = useState(initialThemeValue);
  const [themeType, setThemeType] = useState(defaultContext.themeType);
  const [layout, setLayout] = useState(defaultContext.layout);
  const [cardRadius, setCardRadius] = useState(defaultContext.theme.overrides.MuiCard.root.borderRadius);
  const [direction, setDirection] = useState(initialThemeValue.direction || 'rtl');
  const [showTourGuide, setTourGuideStatus] = useState(false);
  const [logo_img, setLogo_img] = useState('');
  const [colour, setcolour] = useState('Light');
  const dispatch = useDispatch();

  const contextValue = React.useMemo(() => {
    return {
      locale,
      setLocale,
      theme,
      setTheme,
      layout,
      setLayout,
      themeType,
      setThemeType,
      cardRadius,
      setCardRadius,
      direction,
      setDirection,
      showTourGuide,
      setTourGuideStatus,
      logo_img,
      setLogo_img,
    };
  }, [locale, theme, themeType, layout, direction, showTourGuide, cardRadius, logo_img]);

  useEffect(() => {
    // let company_id = localStorage_getItem('company_id');
    let colour = localStorage_getItem('theme');
    setcolour(isNull(colour) ? 'Default' : colour);
    // if (!isUndefined(company_id) && !isNull(company_id)) {
    //   // call api for the company to get the color pallete and logo
    //   dispatch(AuhMethods['jwtCompanyManagement'].getCurrentCompanyDetail(company_id, setcolour, setLogo_img));
    // }
  }, [localStorage_getItem('company_id')]);

  useEffect(() => {
    setTheme(prevState => ({
      ...prevState,
      palette: {
        ...prevState.palette,
        ...otherThemes[themeType].palette,
      },
      overrides: {
        ...prevState.overrides,
        ...otherThemes[themeType].overrides,
      },
    }));
  }, [themeType]);

  useEffect(() => {
    setTheme(prevState => ({
      ...prevState,
      overrides: {
        ...prevState.overrides,
        MuiCard: {
          ...prevState.overrides.MuiCard,
          root: {
            ...prevState.overrides.MuiCard.root,
            borderRadius: cardRadius,
          },
        },
      },
    }));
  }, [cardRadius]);

  useEffect(() => {
    setTheme(prevState => ({
      ...prevState,
      direction: direction,
    }));
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // color changes the theme
  useEffect(() => {
    if (colour.length != 0) {
      setTheme(prevState => ({
        ...prevState,
        palette: {
          ...prevState.palette,
          primary: {
            ...prevState.palette.primary,
            main: theme_obj[colour]['color'][0],
            dark: theme_obj[colour]['color'][1],
          },
          secondary: {
            ...prevState.palette.secondary,
            main: theme_obj[colour]['color'][2],
            dark: theme_obj[colour]['color'][3],
          },
          miscellaneous: {
            main: theme_obj[colour]['color'][4],
          },
        },
      }));
    }
  }, [colour]);

  const location = useLocation();
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    if (params.get('theme-type')) {
      setThemeType(params.get('theme-type'));
    }

    if (params.get('layout')) {
      setLayout(params.get('layout'));
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>;
};

export default AppContextProvider;
